export const contactsData = {
  email: "self@collinsmarra.com",
  phone: "+254710655304",
  address: "Nairobi, Kenya",
  github: "https://github.com/collinsmarra",
  linkedIn: "https://www.linkedin.com/in/collins-kiplagat-423587190/",
  twitter: "https://twitter.com/collinsmarra",
  stackOverflow: "https://stackoverflow.com/users/17142464/collinsmarra",
  devUsername: "collinsmarra",
};
