export const experienceData = [
    {
        id: 1,
        company: 'Mpesa Africa',
        jobtitle: 'Cyber Security Intern',
        startYear: 'March 2022',
        endYear: 'September 2022'
    },
    {
        id: 2,
        company: 'StoreYako',
        jobtitle: 'Full-Stack Developer',
        startYear: 'Jun 2023',
        endYear: 'Present'
    },
]
