import eight from '../assets/svg/projects/auth.svg'
import seven from '../assets/svg/projects/seven.svg'
// import six from '../assets/svg/projects/six.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'StoreYako',
        projectDesc: 'This project is a cloud-based inventory management solution serving as POS. Customers can add product and manage them.',
        tags: ['Rust', 'SAAS', 'WebAssembly', 'Quart', 'React'],
        code: '',
        demo: 'https://storeyako.com/home',
        image: seven
    },
    {
        id: 2,
        projectName: 'Quart-HtTTPAuth',
        projectDesc: 'This library is a HTTP Authentication library for Quart. It is inspired by Flask-HTTPAuth.',
        tags: ['Python' ,'Quart'],
        code: 'https://github.com/collinsmarra/Quart-HTTPAuth',
        demo: 'https://github.com/collinsmarra/Quart-HTTPAuth',
        image: eight
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
